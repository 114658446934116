import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import router from './router/index'
import App from './App.vue'
import http from '@/utils/request'
import i18n from '@/i18n'
import { hasPermission,clearLoginInfo} from '@/utils'
import store from './store'

Vue.use(ElementUI)

Vue.prototype.$hasPermission = hasPermission
Vue.prototype.$clearLoginInfo = clearLoginInfo
Vue.prototype.$http = http
new Vue({
  el: '#app',
  router,
  http,
  i18n,
  store,
  render: h => h(App)
})
