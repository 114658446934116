import Cookies from 'js-cookie'
// import store from '@/store'

/**
 * 权限
 * @param {*} key
 */
export function hasPermission () {
  return localStorage.getItem("permissions")=="true"? true: false;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  // store.commit('resetStore')
  Cookies.remove('token')
  this.$router.push({name:"login"})
  // window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = false
}

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList () {
  var res = []
  var list = document.querySelectorAll('svg symbol')
  for (var i = 0; i < list.length; i++) {
    res.push(list[i].id)
  }

  return res
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'pid') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k])
      continue
    }
    if (!temp[data[k][pid]]['children']) {
      temp[data[k][pid]]['children'] = []
    }
    temp[data[k][pid]]['children'].push(data[k])
    data[k]['_level'] = (temp[data[k][pid]]._level || 0) + 1
  }
  return res
}

/**
 * 主题颜色风格
 */
export const themeColorChangeHandle = (val) => {
	sessionStorage.setItem('themeColor', val)
	var styleList = [
		{
			id: 'J_elementTheme',
			url: `${process.env.BASE_URL}element-theme/${val}/index.css?t=${new Date().getTime()}`
		},
		{
			id: 'J_auiTheme',
			url: `${process.env.BASE_URL}element-theme/${val}/aui.css?t=${new Date().getTime()}`
		}
	]
	for (var i = 0; i < styleList.length; i++) {
		var el = document.querySelector(`#${styleList[i].id}`)
		if (el) {
			el.href = styleList[i].url
			continue
		}
		el = document.createElement('link')
		el.id = styleList[i].id
		el.href = styleList[i].url
		el.rel = 'stylesheet'
		document.querySelector('head').appendChild(el)
	}
}

/*
 * 日期格式化
 */
export const format = (date, fmt) => {
    let o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt)) {//年份
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
}